import React, { useEffect, useState } from 'react';
import { parse, isAfter, isBefore } from 'date-fns';
import { Line } from 'react-chartjs-2';
import Select from 'react-select';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const processes = [
    {
        id: 1,
        name: "Batch start"
    },
    {
        id: 2,
        name: "Pressure Hold"
    },
    {
        id: 3,
        name: "Esip"
    },
    {
        id: 4,
        name: "Fsip"
    },
    {
        id: 5,
        name: "Inoculation"
    },
    {
        id: 6,
        name: "Fermentation"
    },
    {
        id: 8,
        name: "CIP"
    },
];

const PDFDisplay = ({ textListEntries, headerOptions, csvData, client, equipment, equipmentId }) => {
    const [pdfStartDate, setPdfStartDate] = useState('');
    const [pdfEndDate, setPdfEndDate] = useState('');
    const [pdfBatchId, setPdfBatchId] = useState('');
    // const [selectedUser, setSelectedUser] = useState('');
    const [selectedProcessId, setSelectedProcess] = useState(null);
    const [selectedProcessName, setSelectedProcessName] = useState(null);
    const [selectedHeaders, setSelectedHeaders] = useState([]);
    const [tableHeaders, setTableHeaders] = useState([]);
    const [filteredPdfData, setFilteredPdfData] = useState([]);
    const [dataKeys, setDataKeys] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [filters, setFilters] = useState({
        date: [],
        users: [],
        batchId: [],
        processes: []
    });

    const handlePdfStartDateChange = (e) => {
        setPdfStartDate(e.target.value)
        setTimeFilteredData([]);
    };
    const handlePdfEndDateChange = (e) => { setPdfEndDate(e.target.value); setTimeFilteredData([]); };
    const handlePdfBatchChange = (e) => { setPdfBatchId(e.target.value); setTimeFilteredData([]); };
    // const handleUserChange = (e) => setSelectedUser(e.target.value);

    const handleHeaderChange = (selected) => {
        console.log(selected);
        const selectedHeaders = [
            "Date ",
            " Time ",
            " Batch_ID ",
            " Status ",
            ...selected.map(o => o.value)];
        const selectedTableHeaders = [
            "Date",
            "Time",
            "Batch_ID",
            "Status",
            ...selected.map(o => o.label)
        ];
        console.log("selectedTableHeaders", selectedTableHeaders);
        setDataKeys(selectedHeaders);
        setTableHeaders(selectedTableHeaders);
        setSelectedHeaders(selected);
        setTimeFilteredData([]);
        // changeChartHeaders(selectedTableHeaders);
    };

    const handleProcessChange = (e) => {
        const selectedValue = Number(e.target.value); // Ensure the value is a number
        const process = processes.find((o) => o.id === selectedValue);
        setSelectedProcess(selectedValue);
        setSelectedProcessName(process?.name || '');
        console.log("selectedValue", selectedValue); // Debugging
        console.log("process", process); // Debugging
        setTimeFilteredData([]);
    };

    const parseDate = (dateStr) => {
        return parse(dateStr, 'd:M:yyyy', new Date());
    };

    useEffect(() => {
        const filteredPdfData = csvData?.filter(row => {
            const date = parseDate(row["Date "]);
            const startDate = pdfStartDate ? parse(pdfStartDate, 'yyyy-MM-dd', new Date()) : null;
            const endDate = pdfEndDate ? parse(pdfEndDate, 'yyyy-MM-dd', new Date()) : null;
            const dateMatch = (!startDate || !isBefore(date, startDate)) && (!endDate || !isAfter(date, endDate));
            const batchIdMatch = pdfBatchId ? row[" Batch_ID "] === pdfBatchId : true;
            const processMatch = selectedProcessId ? row[" Status "]?.trim() === String(selectedProcessId) : true; // Ensure proper type comparison
            return dateMatch && processMatch && batchIdMatch;
        });

        setFilteredPdfData(filteredPdfData);
    }, [csvData, selectedProcessId, pdfBatchId, pdfStartDate, pdfEndDate]);


    const [timeFrame, setTimeFrame] = useState(1); // Default to 1 minute
    const [timeFilteredData, setTimeFilteredData] = useState(filteredPdfData || csvData || []);

    const setTimeInterval = (val) => {
        setTimeFrame(val)
    }

    const statusConverter = (status) => {
        return textListEntries[status];
    }

    const handleFilter = () => {
        if (timeFrame == 0) {
            setTimeFilteredData(filteredPdfData);
            return;
        }
        let selectedData = [];
        const indexIncreament = Number(timeFrame * 6);

        for (let i = 0; i < filteredPdfData?.length; i += indexIncreament) {
            if (i || i >= 0)
                selectedData.push(filteredPdfData[i]);
        }

        selectedData = selectedData.map(o => { return { ...o, [" Status "]: statusConverter(o?.[" Status "]) } })
        setTimeFilteredData(selectedData);
    };

    useEffect(() => {
        const filteredData1 = filteredPdfData.filter(row => {
            const date = parseDate(row["Date "]);
            const startDate = pdfStartDate ? parse(pdfStartDate, 'yyyy-MM-dd', new Date()) : null;
            const endDate = pdfEndDate ? parse(pdfEndDate, 'yyyy-MM-dd', new Date()) : null;
            const dateMatch = (!startDate || !isBefore(date, startDate)) && (!endDate || !isAfter(date, endDate));
            // const userMatch = selectedUser ? row[" User "] === selectedUser : true;
            const batchIdMatch = pdfBatchId ? row[" Batch_ID "] === pdfBatchId : true;
            const processMatch = selectedProcessId ? row[" Status "] == selectedProcessId : true;
            return (
                dateMatch &&
                // userMatch && 
                processMatch &&
                batchIdMatch
            );
        });

        const dates = [...new Set(filteredData1?.map(item => item["Date "]?.trim()))]?.filter(o => o);
        const users = [...new Set(filteredData1?.map(item => item[" User "]?.trim()))]?.filter(o => o);
        const batchId = [...new Set(filteredData1?.map(item => item[" Batch_ID "]?.trim()))]?.filter(o => o);
        const processObj = [...new Set(filteredData1?.map(item => item[" Status "]?.trim()))]?.filter(o => o);
        const filteredProcesseObj = processes.filter(o => processObj.includes(String(o.id)));

        setFilters({
            date: dates,
            users: users,
            batchId: batchId,
            processes: filteredProcesseObj
        });

    }, [pdfEndDate])

    useEffect(() => {
        changeChartHeaders(tableHeaders);
    }, [timeFilteredData])

    const spValues = filteredPdfData?.length ? filteredPdfData[0] : {};

    const changeChartHeaders = (headers) => {
        const borderColor = [
            'rgba(75, 192, 192, 1)',   // Teal
            'rgba(255, 159, 64, 1)',   // Orange
            'rgba(153, 102, 255, 1)',  // Purple
            'rgba(255, 99, 132, 1)',   // Red
            'rgba(54, 162, 235, 1)',   // Blue
            'rgba(255, 206, 86, 1)',   // Yellow
            'rgba(75, 192, 192, 1)',   // Green
            'rgba(201, 203, 207, 1)',  // Light Grey
            'rgba(255, 205, 86, 1)',   // Gold
            'rgba(153, 102, 255, 1)'   // Violet
        ];
        console.log(headers);
        let chartData = {
            labels: timeFilteredData?.map((row) => {
                const date = row["Date "];   // Assuming " Date " is the date field in your data
                const time = row[" Time "];   // Assuming " Time " is the time field in your data
                return `${date}--${time}`;     // Combine date and time
            }),
            datasets: headers.slice(4)?.map((header, i) => {
                return {

                    label: header,
                    data: filteredPdfData?.map((row) => Number(row[` ${header} `])),
                    borderColor: borderColor[i],
                    fill: false,
                    borderWidth: 1.5, // Thinner line
                    lineTension: 0.3,  // Less curve in the line
                    pointRadius: 1.5,    // Smaller points // Smaller line width
                }
            })
        }
        console.log(chartData);
        setChartData(chartData);
    }
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: 'Current Values Over Time',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Value',
                },
            },
        },
    };
    // Chart End
    return (
        <>
            <div className='row'>
                <div className="mb-4 col-4">
                    <label htmlFor="pdfStartDateFilter" className="mr-2">Start Date:</label>
                    <input type="date" id="pdfStartDateFilter" className="form-control d-inline-block w-100" value={pdfStartDate} onChange={handlePdfStartDateChange} />
                </div>
                <div className="mb-4 col-4">
                    <label htmlFor="pdfEndDateFilter" className="mr-2">End Date:</label>
                    <input type="date" id="pdfEndDateFilter" className="form-control d-inline-block w-100" value={pdfEndDate} onChange={handlePdfEndDateChange} />
                </div>
                <div className="mb-4 col-4">
                    <label htmlFor="pdfBatchIdFilter" className="mr-2">Batch ID:</label>
                    <select id="pdfBatchIdFilter" className="form-control d-inline-block w-100" value={pdfBatchId} onChange={handlePdfBatchChange}>
                        <option value="">All Batch Ids</option>
                        {filters?.batchId?.map((id, index) => (
                            <option key={index} value={id}>{id}</option>
                        ))}
                    </select>
                </div>
                <div className="mb-4 col-4">
                    <label htmlFor="processFilter" className="mr-2">Process:</label>
                    <select
                        id="processFilter"
                        className="form-control d-inline-block w-100"
                        value={selectedProcessId}  // This will ensure the selected value shows up
                        onChange={handleProcessChange}
                    >
                        <option value="">All Processes</option>
                        {processes?.map((process, index) => (
                            <option key={index} value={process.id}>
                                {process.name.toUpperCase()}
                            </option>
                        ))}
                    </select>
                </div>
                {/* <div className="mb-4 col-4">
                    <label htmlFor="userFilter" className="mr-2">User:</label>
                    <select id="userFilter" className="form-control d-inline-block w-100" value={selectedUser} onChange={handleUserChange}>
                        <option value="">All Users</option>
                        {filters?.users?.map((user, index) => (
                            <option key={index} value={user}>{user}</option>
                        ))}
                    </select>
                </div> */}
                <div className='col-4 mb-4'>
                    <label htmlFor="idFilter" className="mr-2">Select Columns:</label>
                    <Select
                        isMulti
                        options={headerOptions}
                        value={selectedHeaders}
                        onChange={handleHeaderChange}
                        placeholder="Select headers..."
                        className="react-select-container"
                        classNamePrefix="react-select"
                    />
                </div>
                <div className="mb-4 col-4">
                    <label htmlFor="intervalInput" className="mr-2">Interval (minutes):</label>
                    <input
                        type="number"
                        id="intervalInput"
                        className="form-control"
                        value={timeFrame}
                        max="60"
                        min="0"
                        onChange={(e) => setTimeInterval(e.target.value)}
                        placeholder="Enter interval in minutes"
                    />
                </div>
                <div className="mb-4 container" style={{
                    "display": "block",
                    "width": "100%",
                    "textAlign": "right"
                }}>
                    {/* <button className="btn btn-secondary mr-2" onClick={handleClearFilters}>Clear Filters</button> */}

                    <button className="btn btn-primary" onClick={handleFilter}>Format Data</button>
                </div>
            </div>
            {
                pdfStartDate && pdfEndDate && pdfBatchId && tableHeaders && timeFilteredData.length ? (
                    <div id="pdf-content" className="pdf-display">
                        <h3>Process Report</h3>
                        <div>
                            <strong>COMPANY:</strong> {client}<br />
                            <strong>EQUIPMENT:</strong> {equipment}<br />
                            <strong>EQUIPMENT ID:</strong> {equipmentId}<br />
                            {/* <strong>PROCESS:</strong> {} */}
                        </div>

                        <h4 className='mt-4'>Set Parameters</h4>
                        <div>
                            <>
                                {tableHeaders.includes("TE_CV") && (
                                    <p className="m-0">
                                        <strong>TE Set Parameter:</strong>{" "}
                                        {spValues[" TE_SP "] ? `${spValues[" TE_SP "]} °C` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("pH_CV") && (
                                    <p className="m-0">
                                        <strong>pH Set Parameter:</strong>{" "}
                                        {spValues[" pH_SP "]
                                            ? `${parseFloat(spValues[" pH_SP "]).toFixed(1)}`
                                            : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("DO_CV") && (
                                    <p className="m-0">
                                        <strong>DO Set Parameter:</strong>{" "}
                                        {spValues[" DO_SP "] ? `${spValues[" DO_SP "]} %` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("RPM_CV") && (
                                    <p className="m-0">
                                        <strong>RPM Set Parameter:</strong>{" "}
                                        {spValues[" RPM_SP "] || "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("AIR_CV") && (
                                    <p className="m-0">
                                        <strong>AIR Set Parameter:</strong>{" "}
                                        {spValues[" AIR_SP "] ? `${spValues[" AIR_SP "]} LPM` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("PR_CV") && (
                                    <p className="m-0">
                                        <strong>PR Set Parameter:</strong>{" "}
                                        {spValues[" PR_SP "] ? `${spValues[" PR_SP "]} bar` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("O2_CV") && (
                                    <p className="m-0">
                                        <strong>O2 Set Parameter:</strong>{" "}
                                        {spValues[" O2_SP "] ? `${spValues[" O2_SP "]} LPM` : "N/A"}
                                    </p>
                                )}
                                {tableHeaders.includes("FSIP_TIME_CV") && (
                                    <p className="m-0">
                                        <strong>FSIP_TIME Set Parameter:</strong>{" "}
                                        {spValues[" FSIP_TIME_SP "]
                                            ? `${spValues[" FSIP_TIME_SP "]} minutes`
                                            : "N/A"}
                                    </p>
                                )}
                            </>



                        </div>
                        <h4 className='mt-4 mb-2'>Batch Data</h4>
                        <div>
                            <strong>EQUIPMENT ID:</strong> EUP-UPS-Q15<br />
                            {/* <strong>OPERATOR CODE:</strong> {selectedUser || 'All'}<br /> */}
                            <strong>DATE:</strong> {pdfStartDate} - {pdfEndDate}<br />
                            {/* <strong>TIME:</strong> {minTime}<br /> */}
                            <strong>PROCESS:</strong> {selectedProcessName || 'All'}<br />
                        </div>
                        <h4 className='mt-4 mb-2'>Current Values</h4>
                        <table style={{ borderCollapse: 'collapse', tableLayout: 'fixed', width: '100%' }}>
                            <thead>
                                <tr>
                                    {tableHeaders.map((header, index) => {
                                        const splitHeader = header.split('_'); // Split the header by the underscore
                                        const columnWidth = `${100 / tableHeaders.length}%`; // Calculate width based on number of columns
                                        return (
                                            <th key={index} style={{ ...headerStyle, width: columnWidth }}>
                                                {splitHeader[0]} <br /> {splitHeader[1]} {/* Display first part and second part on different lines */}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {timeFilteredData?.length > 0 && timeFilteredData?.map((row, index) => (
                                    <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#fff' }}>
                                        {dataKeys.map((key) => (
                                            <td key={key} style={{ ...cellStyle, width: `${100 / tableHeaders.length}%`, textAlign: 'center' }}>
                                                {/* Ensure two decimal places for numeric columns */}
                                                {["TE_CV", "pH_CV", "DO_CV", "RPM_CV", "AIR_CV", "PR_CV", "O2_CV", "LEVEL", "IN_FL_TEMP", "EX_FL_TEMP", "DRAIN_TEMP", "PH_TIME_CV", "ESIP_TIME_CV", "FSIP_TIME_CV", "CIP_TIME_CV"].includes(key.trim())
                                                    ? key.trim() === "CIP_TIME_CV" ? Number(row[" CIP_TIME_CV"]?.trim()).toFixed(2) || "N/A" : Number(row[key]?.trim()).toFixed(2) || "N/A"
                                                    : row[key]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                {!filteredPdfData?.length && (
                                    <tr>
                                        <td colSpan={tableHeaders.length} style={{ textAlign: 'center' }}>No data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <div className="page-break" style={{ pageBreakBefore: 'always' }}>
                            {/* Line Chart for current values */}
                            <div className="line-chart-container" style={{ width: '600px', height: '300px' }}>
                                {filteredPdfData.length > 0 && (
                                    <Line data={chartData} options={chartOptions} />
                                )}
                            </div>

                            {/* Existing table and PDF display code */}
                        </div>

                        {/* Additional Remarks and Signature Section */}
                        {pdfStartDate && pdfEndDate && pdfBatchId && (
                            <div id="pdf-content" className="pdf-display">
                                {/* Existing report content */}

                                {/* Page break for remarks and signature section */}
                                <div className="page-break">
                                    {/* Remarks Section */}
                                    <div className="mt-4">
                                        <h4>Remarks</h4>
                                        <textarea className="form-control" rows="4" placeholder="Enter any remarks here..."></textarea>
                                    </div>

                                    {/* Signature Section */}
                                    <div className="mt-5">
                                        <h4 className='mb-5'>Signatures</h4>
                                        <div className="row">
                                            <div className="col-4">
                                                <p><strong>Done by:</strong></p>
                                                <p>____________________________</p>
                                            </div>
                                            <div className="col-4">
                                                <p><strong>Checked by:</strong></p>
                                                <p>____________________________</p>
                                            </div>
                                            <div className="col-4">
                                                <p><strong>Approved by:</strong></p>
                                                <p>____________________________</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : <>{Object.keys(spValues).length === 0 ? <div>No Data Available</div> : <div>Press Format Data Button</div>}</>
            }
        </>
    );
};

const cellStyle = {
    border: '1px solid #ddd',
    padding: '2px',
    textAlign: 'left',
    fontSize: '10px',
    wordWrap: 'break-word',
    overflow: 'hidden'
};

const headerStyle = {
    ...cellStyle,
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    textAlign: 'center'
};

export default PDFDisplay;