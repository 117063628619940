import React, { useEffect, useState } from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import html2pdf from 'html2pdf.js';
import { parse, isAfter, isBefore } from 'date-fns';
import PDFDisplay from './PDFDisplay';

import Select from 'react-select';

const processes = [
  {
    id: 1,
    name: "Batch start"
  },
  {
    id: 2,
    name: "Pressure Hold"
  },
  {
    id: 3,
    name: "Esip"
  },
  {
    id: 4,
    name: "Fsip"
  },
  {
    id: 5,
    name: "Inoculation"
  },
  {
    id: 6,
    name: "Fermentation"
  },
  {
    id: 8,
    name: "CIP"
  },
];

const headerOptions = [
  { value: ' TE_CV ', label: 'TE_CV' },
  { value: ' pH_CV ', label: 'pH_CV' },
  { value: ' DO_CV ', label: 'DO_CV' },
  { value: ' RPM_CV ', label: 'RPM_CV' },
  { value: ' AIR_CV ', label: 'AIR_CV' },
  { value: ' PR_CV ', label: 'PR_CV' },
  { value: ' O2_CV ', label: 'O2_CV' },
  { value: ' LEVEL ', label: 'LEVEL' },
  { value: ' IN_FL_TEMP ', label: 'IN_FL_TEMP' },
  { value: ' EX_FL_TEMP ', label: 'EX_FL_TEMP' },
  { value: ' DRAIN_TEMP ', label: 'DRAIN_TEMP' },
  { value: ' PH_TIME_CV ', label: 'PH_TIME_CV' },
  { value: ' ESIP_TIME_CV ', label: 'ESIP_TIME_CV' },
  { value: ' FSIP_TIME_CV ', label: 'FSIP_TIME_CV' },
  { value: ' CIP_TIME_CV ', label: 'CIP_TIME_CV' },
]

const textListEntries = {
  1: "HEATING",
  2: "HOLDING",
  3: "COOLING",
  4: "END",
  5: "PRESSURIZATION",
  6: "PRESSURE HOLD",
  7: "PRESSURE END",
  8: "STABILIZATION"
};

const App = () => {
  const [csvData, setCsvData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);
  const [filters, setFilters] = useState({
    date: [],
    users: [],
    batchId: [],
    processes: []
  });
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedBatchId, setSelectedBatchId] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isCsvFile, setIsCsvFile] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'asc' });
  const [activeTab, setActiveTab] = useState('table');
  const [selectedProcessId, setSelectedProcess] = useState(null);
  const [selectedProcessName, setSelectedProcessName] = useState(null);
  const [selectedHeaders, setSelectedHeaders] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);

  const [mfgBy, setMfgBy] = useState('M/s. MACHINAFABRIK INDUSTRIES');
  const [client, setClient] = useState('');
  const [equipment, setEquipment] = useState('');
  const [equipmentId, setEquipmentId] = useState('');
  const [process, setProcess] = useState('BOWIE & DICK TEST');


  const handleHeaderChange = (selected) => {
    console.log(selected);
    const selectedHeaders = [
      "Date ",
      " Time ",
      " Batch_ID ",
      " Status ",
      ...selected.map(o => o.value)];
    const selectedTableHeaders = [
      "Date",
      "Time",
      "Batch_ID",
      "Status",
      ...selected.map(o => o.label)
    ];
    console.log(selectedHeaders);
    setDataKeys(selectedHeaders);
    setTableHeaders(selectedTableHeaders);
    setIsCsvFile(true);
    setSelectedHeaders(selected);
    setIsDataLoaded(true);
  };

  const handleProcessChange = (e) => {
    const selectedValue = Number(e.target.value); // Ensure the value is a number
    const process = processes.find((o) => o.id === selectedValue);
    console.log(e.target.value);
    console.log(selectedValue);
    setSelectedProcess(selectedValue);
    setSelectedProcessName(process?.name || '');
  };

  const handleFileUpload = (e) => {
    setSelectedStartDate('');
    setSelectedEndDate('');
    setSelectedProcess('');
    setSelectedProcessName('');
    const file = e.target.files[0];
    console.log(file);
    setClient('M/s. EPYGEN BIOTECH PVT. LTD.');
    if (file.name.toLowerCase().includes("f1")) {
      setMfgBy('M/s. Epygen Biotech Pvt. Ltd.');
      setEquipment('20 L Fermenter');
      setEquipmentId('EUP-FER-007');
    } else if (file.name.toLowerCase().includes("f2")) {
      setMfgBy('M/s. Epygen Biotech Pvt. Ltd');
      setEquipment('150 L Fermenter');
      setEquipmentId('EUP-FER-008');
    }
    if (!file) return;

    const fileExtension = file.name?.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {
      Papa.parse(file, {
        complete: (result) => {

          setCsvData(result.data);
          setDataKeys([
            "Date ",
            " Time ",
            " Batch_ID ",
            " Status ",
            " TE_CV ",
            " pH_CV ",
            " DO_CV ",
            " RPM_CV ",
            " AIR_CV ",
            " PR_CV ",
            " O2_CV ",
            " LEVEL ",
            " IN_FL_TEMP ",
            " EX_FL_TEMP ",
            " DRAIN_TEMP ",
            " PH_TIME_CV ",
            " ESIP_TIME_CV ",
            " FSIP_TIME_CV ",
            " CIP_TIME_CV "
          ]);
          setIsDataLoaded(true);
          setIsCsvFile(true);
        },
        header: true,
      });
    } else {
      alert("Please upload a valid CSV file.");
    }
  };

  const handleStartDateChange = (e) => setSelectedStartDate(e.target.value);
  const handleEndDateChange = (e) => {
    setSelectedEndDate(e.target.value);
  };

  const statusConverter = (status) => {
    return textListEntries[status];
  }

  useEffect(() => {
    const filteredData1 = sortedData.filter(row => {
      const date = parseDate(row["Date "]);
      const startDate = selectedStartDate ? parse(selectedStartDate, 'yyyy-MM-dd', new Date()) : null;
      const endDate = selectedEndDate ? parse(selectedEndDate, 'yyyy-MM-dd', new Date()) : null;
      const dateMatch = (!startDate || !isBefore(date, startDate)) && (!endDate || !isAfter(date, endDate));
      const userMatch = selectedUser ? row[" User "] === selectedUser : true;
      const batchIdMatch = selectedBatchId ? row[" Batch_ID "] === selectedBatchId : true;
      const processMatch = selectedProcessId ? row[" Status "] == selectedProcessId : true;
      return dateMatch && userMatch && processMatch && batchIdMatch;
    });

    const dates = [...new Set(filteredData1?.map(item => item["Date "]?.trim()))]?.filter(o => o);
    const users = [...new Set(filteredData1?.map(item => item[" User "]?.trim()))]?.filter(o => o);
    const batchId = [...new Set(filteredData1?.map(item => item[" Batch_ID "]?.trim()))]?.filter(o => o);
    const processObj = [...new Set(filteredData1?.map(item => item[" Status "]?.trim()))]?.filter(o => o);
    const filteredProcesseObj = processes.filter(o => processObj.includes(String(o.id)));
    console.log(filteredData1, dates, users, batchId);
    setFilters({
      date: dates,
      users: users,
      batchId: batchId,
      processes: filteredProcesseObj
    });
  }, [selectedEndDate])

  const handleUserChange = (e) => setSelectedUser(e.target.value);
  const handleBatchChange = (e) => setSelectedBatchId(e.target.value);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const parseTime = (timeStr) => {
    const [hours, minutes, seconds] = timeStr?.split(':').map(num => parseInt(num, 10));
    return (hours * 3600) + (minutes * 60) + (seconds || 0);
  };

  const parseDate = (dateStr) => {
    return parse(dateStr, 'd:M:yyyy', new Date());
  };

  const sortedData = [...csvData].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      if (aValue && bValue) {
        if (sortConfig.key === 'Date ' || sortConfig.key === ' Time ') {
          if (sortConfig.key === 'Date ') {
            return sortConfig.direction === 'asc'
              ? parseDate(aValue) - parseDate(bValue)
              : parseDate(bValue) - parseDate(aValue);
          } else if (sortConfig.key === ' Time ') {
            return sortConfig.direction === 'asc'
              ? parseTime(aValue) - parseTime(bValue)
              : parseTime(bValue) - parseTime(aValue);
          }
        }
        return sortConfig.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
    }
    return 0;
  });

  const filteredData = sortedData.filter(row => {
    const date = parseDate(row["Date "]);
    const startDate = selectedStartDate ? parse(selectedStartDate, 'yyyy-MM-dd', new Date()) : null;
    const endDate = selectedEndDate ? parse(selectedEndDate, 'yyyy-MM-dd', new Date()) : null;
    const dateMatch = (!startDate || !isBefore(date, startDate)) && (!endDate || !isAfter(date, endDate));
    const userMatch = selectedUser ? row[" User "] === selectedUser : true;
    const batchIdMatch = selectedBatchId ? row[" Batch_ID "] === selectedBatchId : true;
    const processMatch = selectedProcessId ? row[" Status "] == selectedProcessId : true;
    return dateMatch && userMatch && processMatch && batchIdMatch;
  });

  const dateTimeToSeconds = (date, time) => {
    if (!date || !time) return;
    const [day, month, year] = date?.split(':').map(Number);
    const [hours, minutes, seconds] = time?.split(':').map(Number);

    // Calculate total seconds considering date and time
    const dateInSeconds = (new Date(year, month - 1, day, hours, minutes, seconds).getTime()) / 1000;
    return dateInSeconds;
  };

  const [timeFrame, setTimeFrame] = useState(1); // Default to 1 minute
  const [timeFilteredData, setTimeFilteredData] = useState(filteredData || csvData || []);

  const setTimeInterval = (val) => {
    setTimeFrame(val)
  }

  const handleFilter = () => {
    if (timeFrame == 0) {
      setTimeFilteredData(filteredData);
      return;
    }
    let selectedData = [];
    const indexIncreament = Number(timeFrame * 6);

    for (let i = 0; i < filteredData?.length; i += indexIncreament) {
      if (i || i >= 0)
        selectedData.push(filteredData[i]);
    }
    selectedData = selectedData.map(o => { return { ...o, [" Status "]: statusConverter(o?.[" Status "]) } })
    setTimeFilteredData(selectedData);
  };


  const handleClearFilters = () => {
    setSelectedStartDate('');
    setSelectedEndDate('');
    setSelectedUser('');
    setSelectedBatchId('');
    setTimeFilteredData(filteredData || csvData || []);
    setTimeFrame(1);
  };

  const downloadExcel = () => {

    // Prepare main data for Excel
    const dataForExcel = timeFilteredData.map(row => {
      // const dataForExcel = filteredData.map(row => {
      let otherColumns = {}
      tableHeaders.forEach(columnName => {
        if (columnName.includes("Date")) {
          otherColumns["Date"] = row["Date "];
        } else if (columnName.includes("Time")) {
          otherColumns["Time"] = row[" Time "];
        } else if (columnName.includes("Batch_ID")) {
          otherColumns["Batch_ID"] = row[" Batch_ID "];
        } else if (columnName.includes("Status")) {
          otherColumns["Status"] = row[" Status "];
        } else {
          const column = ` ${columnName} `
          otherColumns[columnName] = Number(`${row[column]}`).toFixed(2);
        }
      });

      return otherColumns;
    });

    console.log("dataForExcel", dataForExcel);

    const setParameterAll = {
      "TE_SP": ["TE Set Parameter", timeFilteredData?.[1][" TE_SP "] ? `${timeFilteredData?.[1][" TE_SP "]} °C` : 'N/A'],
      "pH_SP": ["pH Set Parameter", timeFilteredData?.[1][" pH_SP "] ? `${parseFloat(timeFilteredData?.[1][" pH_SP "]).toFixed(1)}` : 'N/A'],
      "DO_SP": ["DO Set Parameter", timeFilteredData?.[1][" DO_SP "] ? `${timeFilteredData?.[1][" DO_SP "]} %` : 'N/A'],
      "RPM_SP": ["RPM Set Parameter", timeFilteredData?.[1][" RPM_SP "] || 'N/A'],
      "AIR_SP": ["AIR Set Parameter", timeFilteredData?.[1][" AIR_SP "] ? `${timeFilteredData?.[1][" AIR_SP "]} LPM` : 'N/A'],
      "PR_SP": ["PR Set Parameter", timeFilteredData?.[1][" PR_SP "] ? `${timeFilteredData?.[1][" PR_SP "]} bar` : 'N/A'],
      "O2_SP": ["O2 Set Parameter", timeFilteredData?.[1][" O2_SP "] ? `${timeFilteredData?.[1][" O2_SP "]} LPM` : 'N/A'],
      "FSIP_TIME_SP": ["FSIP_TIME Set Parameter", timeFilteredData?.[1][" FSIP_TIME_SP "] ? `${timeFilteredData?.[1][" FSIP_TIME_SP "]} minutes` : 'N/A'],
    }

    let setParameterSelected = [];
    const myTableHeader = tableHeaders.slice(4)?.map(o => o.split('_')[0] + "_SP");
    myTableHeader.forEach(o => {
      setParameterSelected.push(setParameterAll[o]);
    })

    // Prepare additional data
    const additionalData = [
      ["COMPANY", client],
      ["EQUIPMENT", equipment],
      ["EQUIPMENT ID", equipmentId],
      [],
      ["SET PARAMETERS"],
      ...setParameterSelected,
      [], // Empty row for spacing
      ["Batch Id", selectedBatchId || 'N/A'],
      ["Start Date", selectedStartDate || 'N/A'],
      ["End Date", selectedEndDate || 'N/A'],
      [], // Empty row for spacing
      tableHeaders // Add headers for main table
    ];
    const origin = "A" + (12 + setParameterSelected.length);
    // Convert additional data to a worksheet (as 2D array)
    const additionalWorksheet = XLSX.utils.aoa_to_sheet(additionalData);

    // Convert main data to a worksheet and start from row 5 (after additional data)
    XLSX.utils.sheet_add_json(additionalWorksheet, dataForExcel, { origin: origin, skipHeader: true });

    // Create a new workbook and append the combined sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, additionalWorksheet, "Report");

    // Save the workbook
    XLSX.writeFile(workbook, "report.xlsx");
  };


  const generatePDF = () => {
    const element = document.getElementById('pdf-content');
    const opt = {
      margin: 0.5,
      filename: 'process_report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    html2pdf().from(element).set(opt).save();
  };

  return (
    <div className="container mt-5 mb-5">
      <h1 className="display-4 text-center mb-4">Report Generator</h1>
      <div className="text-center mb-4">
        <input type="file" accept=".csv" className="form-control-file btn btn-dark p-4" onChange={handleFileUpload} />
      </div>
      <div className="mb-4 text-center">
        <div className="mb-4 text-center">
          <button
            className={`btn mr-2 ${activeTab === 'table' ? 'btn-primary' : 'btn-secondary'}`}
            onClick={() => setActiveTab('table')}
          >
            Table
          </button>
          <button
            className={`btn ${activeTab === 'pdf' ? 'btn-primary' : 'btn-secondary'}`}
            onClick={() => setActiveTab('pdf')}
          >
            PDF
          </button>
        </div>
      </div>
      <div className='container'>
        {client && <div className="mb-4">
          <label>COMPANY:</label>
          <input type="text" className="form-control" value={client} onChange={(e) => setClient(e.target.value)} />
        </div>}
        {equipment && <div className="mb-4">
          <label>EQUIPMENT:</label>
          <input type="text" className="form-control" value={equipment} onChange={(e) => setEquipment(e.target.value)} />
        </div>}
        {equipmentId && <div className="mb-4">
          <label>EQUIPMENT ID:</label>
          <input type="text" className="form-control" value={equipmentId} onChange={(e) => setEquipment(e.target.value)} />
        </div>}
      </div>

      {
        activeTab === 'table' && isDataLoaded ? (
          <>
            <div className="container mt-4">
              <div className='row'>
                <div className="mb-4 col-4">
                  <label htmlFor="startDateFilter" className="mr-2">Start Date:</label>
                  <input type="date" id="startDateFilter" className="form-control d-inline-block w-100" value={selectedStartDate} onChange={handleStartDateChange} />
                </div>
                <div className="mb-4 col-4">
                  <label htmlFor="endDateFilter" className="mr-2">End Date:</label>
                  <input type="date" id="endDateFilter" className="form-control d-inline-block w-100" value={selectedEndDate} onChange={handleEndDateChange} />
                </div>
                <div className="mb-4 col-4">
                  <label htmlFor="idFilter" className="mr-2">Filter by Batch:</label>
                  <select id="idFilter" className="form-control d-inline-block w-100" value={selectedBatchId} onChange={handleBatchChange}>
                    <option value="">All Batch Ids</option>
                    {filters.batchId.map((id, index) => (
                      <option key={index} value={id}>{id}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-4 col-4">
                  <label htmlFor="processFilter" className="mr-2">Process:</label>
                  <select
                    id="processFilter"
                    className="form-control d-inline-block w-100"
                    value={selectedProcessId}  // This will ensure the selected value shows up
                    onChange={handleProcessChange}
                  >
                    <option value="">All Processes</option>
                    {filters?.processes?.map((process, index) => (
                      <option key={index} value={process.id}>
                        {process.name.toUpperCase()}
                      </option>
                    ))}
                  </select>

                </div>
                <div className='col-4 mb-4'>
                  <label htmlFor="idFilter" className="mr-2">Select Columns:</label>
                  <Select
                    isMulti
                    options={headerOptions}
                    value={selectedHeaders}
                    onChange={handleHeaderChange}
                    placeholder="Select headers..."
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>
                <div className="mb-4 col-4">
                  <label htmlFor="intervalInput" className="mr-2">Interval (minutes):</label>
                  <input
                    type="number"
                    id="intervalInput"
                    className="form-control"
                    value={timeFrame}
                    max="60"
                    min="0"
                    onChange={(e) => setTimeInterval(e.target.value)}
                    placeholder="Enter interval in minutes"
                  />
                </div>
                <div className="mb-4 container" style={{
                  "display": "block",
                  "width": "100%",
                  "textAlign": "right"
                }}>
                  {/* <button className="btn btn-secondary mr-2" onClick={handleClearFilters}>Clear Filters</button> */}

                  <button className="btn btn-primary" onClick={handleFilter}>Format Data</button>
                </div>

              </div>
            </div>
            <div className="container mt-4">
              {
                selectedStartDate &&
                  selectedEndDate &&
                  selectedBatchId &&
                  tableHeaders?.length &&
                  selectedHeaders?.length &&
                  timeFilteredData?.length ? (
                  <>
                    <div id="report" style={{ width: '100%' }}>
                      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}> {/* Horizontal scroll container */}
                        <table className="table table-bordered" style={{ width: '100%', minWidth: '1200px' }}>
                          <thead>
                            <tr>
                              <td colSpan={1} className=""></td>
                              <td colSpan={3} className=""><strong>Batch ID:</strong> {selectedBatchId}</td>
                              <td colSpan={3} className=""><strong>Batch:</strong> {selectedBatchId}</td>
                              <td colSpan={12} className=""><strong>Start Date:</strong> {selectedStartDate}</td>
                            </tr>
                            <tr>
                              <td colSpan={1} className=""></td>
                              <td colSpan={3} className=""><strong>Equipment Code:</strong> {equipment}</td>
                              <td colSpan={3} className=""><strong>Equipment Name:</strong> {equipment}</td>
                              <td colSpan={12} className=""><strong>End Date:</strong> {selectedEndDate}</td>
                            </tr>
                            <tr>
                              {
                                tableHeaders.map((header, index) => {
                                  if (header === 'Date') {
                                    return (
                                      <th key={index} onClick={() => handleSort('Date')}>
                                        Date {sortConfig.key === 'Date' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                                      </th>
                                    );
                                  }
                                  if (header === 'Time') {
                                    return (
                                      <th key={index} onClick={() => handleSort('Time')}>
                                        Time {sortConfig.key === 'Time' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}
                                      </th>
                                    );
                                  }
                                  return (
                                    <th key={index}>
                                      {header}
                                    </th>
                                  );
                                })
                              }

                            </tr>
                          </thead>
                          <tbody>
                            {timeFilteredData.map((row, index) => (
                              <tr key={index}>
                                {dataKeys.map((key) => (
                                  <td key={key}>
                                    {/* Ensure two decimal places for numeric columns */}
                                    {["TE_CV", "pH_CV", "DO_CV", "RPM_CV", "AIR_CV", "PR_CV", "O2_CV", "LEVEL", "IN_FL_TEMP", "EX_FL_TEMP", "DRAIN_TEMP", "PH_TIME_CV", "ESIP_TIME_CV", "FSIP_TIME_CV", "CIP_TIME_CV"].includes(key.trim())
                                      ? Number(row[key]?.trim()).toFixed(2) || "N/A"
                                      : row[key]}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>


                    <div className="text-center mt-4">
                      <button className="btn btn-primary mr-3" onClick={downloadExcel}>Download as Excel</button>
                    </div>
                  </>
                ) : null}
            </div>
          </>
        ) : null
      }
      {
        activeTab === 'pdf' ? (
          <div className="container mt-4">
            <PDFDisplay textListEntries={textListEntries} headerOptions={headerOptions} csvData={csvData} client={client} equipment={equipment} equipmentId={equipmentId} />
            <div className="text-center mt-4">
              <button className="btn btn-primary" onClick={generatePDF}>Download PDF</button>
            </div>
          </div>
        ) : null
      }
    </div >
  );
};

const cellStyle = {
  border: '1px solid #ddd',
  padding: '2px',
  textAlign: 'left',
  fontSize: '10px',
  wordWrap: 'break-word',
  overflow: 'hidden'
};

const headerStyle = {
  ...cellStyle,
  backgroundColor: '#f2f2f2',
  fontWeight: 'bold',
  textAlign: 'center'
};

const styles = {
  multiSelect: {
    width: '100%',
    height: '150px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    color: '#333',
    fontSize: '16px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    transition: 'border-color 0.3s, box-shadow 0.3s',
    outline: 'none',
  },
  selectFocus: {
    borderColor: '#007bff',
    boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)',
  },
};

export default App;